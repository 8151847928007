<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="pt10">
								<strong>Catálogo de Administración de servicios</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
						</div>
						<div class="col-md-12">
							<a-input-search v-model="searchText" placeholder="Ingresa parámetros de búsqueda" enter-button @search="onSearch" />
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<a-table :columns="columns" :dataSource="serviceAdministrationList" rowKey="id">
								<a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
								<div slot="service_name" slot-scope="record">
									<div class="text-left">
										{{ record.service_name }}
									</div>
								</div>
								<div slot="description" slot-scope="record">
									<div class="text-left">
										{{ record.description | replaceIfLogicalFalse('-No establecido-') }}
									</div>
								</div>
								<div slot="active" slot-scope="record">
									<div class="text-center">
										<a-tag color="green" v-if="!!record.active">Activo</a-tag>
										<a-tag color="red" v-else>Inactivo</a-tag>
									</div>
								</div>
								<div slot="action" slot-scope="record">
									<a-button type="info" size="small" icon="edit" @click="onEditRecord(record)" />
									<a-button class="ml7" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onToggleRecord(record.id, record.active)" />
								</div>
							</a-table>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
		<insertOrUpdateServiceAdministration :visible="modal.visible" :title="modal.title" :payload="modal.payload" @closeModal="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import insertOrUpdateServiceAdministration from '@/components/insertOrUpdateServiceAdministration'

export default {
	name: 'serviceAdministration',
	components: {
		insertOrUpdateServiceAdministration,
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Nombre del servicio',
					scopedSlots: { customRender: 'service_name' },
					align: 'center',
				},
				{
					title: 'Descripción',
					scopedSlots: { customRender: 'description' },
					align: 'center',
				},

				{
					title: 'Estatus',
					scopedSlots: { customRender: 'active' },
					align: 'center',
				},
			],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
			searchText: '',
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('serviceAdministration', ['serviceAdministrationList', 'spinnerLoaderLabel', 'spinnerLoader']),
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	beforeMount() {
		if (this.isUserAdmin) {
			this.columns.push({
				title: 'Acciones',
				scopedSlots: { customRender: 'action' },
				align: 'right',
			})
		}
	},
	mounted() {
		if (this.serviceAdministrationList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.searchText = ''
			this.$store.dispatch('serviceAdministration/GET')
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: 'Añadir registro de Servicio',
				payload: {},
			}
		},
		onEditRecord(record) {
			this.modal = {
				visible: true,
				title: 'Editar registro de Servicio',
				payload: _.cloneDeep(record),
			}
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (status) {
						this.$store.dispatch('serviceAdministration/DISABLE', id)
					} else {
						this.$store.dispatch('serviceAdministration/ENABLE', id)
					}
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
		onSearch() {
			//
			this.$store.dispatch('serviceAdministration/SEARCH', {
				searchText: this.searchText,
			})
		},
	},
}
</script>